import PageNav from "./components/PageNav";

export default function PrivacyPolicy() {
  return (
    <>
      <PageNav />
      <Policy />
    </>
  );
}

function Policy() {
  return (
    <div className=" flex justify-center bg-white px-6 py-10 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center ">
          Terms of Use And Privacy Policy
        </h1>{" "}
        {/* Gear up for an exhilarating journey of imagination and personalization with us!*/}
        <p className="mt-6 text-l leading-8">
          <div> Last Updated: June 22, 2024</div>
          Thank you for visiting MyTaleMaker. We are committed to protecting
          your privacy and providing a safe online experience for children. This
          Privacy Policy outlines how we collect, use, and safeguard personal
          information, particularly from children under the age of 13, in
          accordance with the Children’s Online Privacy Protection Act (COPPA)
          and other relevant regulations.
        </p>
        <div className="mt-10 max-w-3xl">
          <ul
            role="list"
            className="list-disc mt-8 max-w-3xl space-y-8 text-gray-600 "
          >
            <li className="flex gap-x-3">
              <span>
                <strong className="font-semibold text-gray-900">
                  Usage Guidance:
                </strong>{" "}
                The process of story creation is the responsibility of an adult.
                Therefore, only an adult should be involved in sending an input
                for story creation, not a child. Children are not permitted to
                directly send input to the service. While the final product is
                intended for children's consumption, it is also intended to be
                used under adult supervision only.
              </span>
            </li>

            <li className="flex gap-x-3">
              <span>
                <strong className="font-semibold text-gray-900">
                  Content Review Responsibility:
                </strong>{" "}
                Adult users are responsible for ensuring that any content
                generated through our service is appropriate for children. Users
                must perform a thorough review of the generated stories to
                confirm they are free from profanity and other content
                inappropriate for a child audience.
              </span>
            </li>

            <li className="flex gap-x-3">
              <span>
                <strong className="font-semibold text-gray-900">
                  Prohibited Uses:
                </strong>{" "}
                Users are strictly prohibited from using MyTaleMaker to:
                <ul className="list-disc pl-5 mt-2 space-y-2">
                  <li>
                    Generate or facilitate Child Sexual Abuse Material or any
                    content that exploits or abuses minors.
                  </li>
                  <li>
                    Generate or facilitate content that is hateful, harmful, harassing,
                     or violent, including content that:
                    <ul className="list-disc pl-5 mt-2 space-y-2">
                      <li>
                        Expresses, or promotes hate or discriminatory
                        practices based on identity such as race, 
                        religion, nationality, ethnicity, gender, or sexual
                        orientation.
                      </li>
                   {/*}   <li>
                        Targets or threatens an individual with abuse,
                        practices based on identity such as race, 
                        harassment, or bullying. 
  </li> */}
                      <li>
                        Promotes or glorifies violence.
                      </li>
                      <li>
                        Encourages, or depicts acts of self-harm, such
                        as suicide.
                      </li>
                    </ul>
                  </li>
                </ul>
              </span>
            </li>

            <li className="flex gap-x-3">
              <span>
                <strong className="font-semibold text-gray-900">
                  User Input Accountability:
                </strong>{" "}
                While we may employ filters to detect and block inappropriate
                content in inputs and outputs, the responsibility for the
                appropriateness of the generated output lies with the user. If
                an adult user submits input that is not suitable for children
                and receives a corresponding output, the user assumes full
                responsibility for that content.
              </span>
            </li>

            <li className="flex gap-x-3">
              <span>
                <strong className="font-semibold text-gray-900">
                  Information Collection:
                </strong>{" "}
                We do not collect any personal information directly from a child
                under 13 years of age. All information provided by users for
                story generation purposes is done so voluntarily and is used
                solely for the creation of the story. Our website uses cookies
                and Google Analytics to enhance your browsing experience and
                help us understand how users use our services.
              </span>
            </li>
            <li className="flex gap-x-3">
              <span>
                <strong className="font-semibold text-gray-900">
                  Purpose of Information Usage:
                </strong>{" "}
                We only request the personal information that is essential for
                providing our services. All information provided by the user for
                story generation is only used for the story creation process. We
                do not use this information for any other purpose, and we do not
                disclose it to third parties unless required for the provision
                of our services.
              </span>
            </li>

            <li className="flex gap-x-3">
              <span>
                <strong className="font-semibold text-gray-900">
                  Data Usage And Security:
                </strong>{" "}
                We do not engage in the sale or commercial use of personal
                information. We use third-party services and APIs to provide our
                service, including story creation and payment processing, and
                may share personal information solely for provisioning our
                service. We
                strive to maintain strict confidentiality and implement security
                measures to protect the data collected; however, as no method of
                transmission over the internet or electronic storage is 100%
                secure, we cannot guarantee absolute security.
              </span>
            </li>
            {/*       <li className="flex gap-x-3">
                
                <span>
                  <strong className="font-semibold text-gray-900">
                  Parental Rights:{" "}
                  </strong>{" "}
                  Parents or guardians have the right to review, modify, or delete any personal information provided by them or their children. They can also request to opt-out of further collection or use of their child’s information.
                  You can locate the PDF in your library for downloading and further enjoyment. 
                </span>
              </li>
             
              <li className="flex gap-x-3">
                
                <span>
                  <strong className="font-semibold text-gray-900">
                  Data Security:{" "}
                  </strong>{" "}
                  We maintain appropriate technical and organizational measures to safeguard personal information collected from users. This includes encryption, secure storage practices, and limiting access to authorized personnel only.


                </span> 
              </li> 
              
            <li className="flex gap-x-3">
              <span>
                <strong className="font-semibold text-gray-900">
                  Data Retention:
                </strong>
                We retain personal information collected from users only for as
                long as necessary to fulfill the purpose for which it was
                collected.
              </span>
  </li> */}
            <li className="flex gap-x-3">
              <span>
                <strong className="font-semibold text-gray-900">
                  Consent to Privacy Practices:
                </strong>{" "}
                By using our website and services, you consent to the terms
                outlined in this Terms of Use And Privacy Policy.
              </span>
            </li>
            <li className="flex gap-x-3">
              <span>
                <strong className="font-semibold text-gray-900">
                  Contact Us:
                </strong>{" "}
                If you have any questions or concerns about our privacy
                practices or this Terms of Use And Privacy Policy, please
                contact us at
                <a
                  href="mailto: contact@mytalemaker.com"
                  className="font-semibold text-gray-600 hover:text-gray-500"
                >
                  contact@mytalemaker.com
                </a>
              </span>
            </li>
            <li className="flex gap-x-3">
              <span>
                <strong className="font-semibold text-gray-900">
                  Changes to this Privacy Policy:
                </strong>{" "}
                We reserve the right to update or revise this Privacy Policy and
                Terms of Service at any time. Any changes will be posted on this
                page with the updated date. We encourage you to review this
                Privacy Policy periodically for any updates.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
