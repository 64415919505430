//this is an extension of library
import React from "react";
import { useLocation } from "react-router-dom";
import PageNav from "./components/PageNav";
import { useState, useEffect } from "react";
import { UseUserAuth } from "./context/UseAuthContext";

export default function BookDetails() {
  const location = useLocation();
  const { book } = location.state;
  const [images, setImages] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const { user } = UseUserAuth();
  const storyTitle = book[0][0];
  //console.log("title", storyTitle);
  const imageNum = book[1][0]; //this is a string and has to be split at ',' to get the digits in an array
  // const prev_counter = parseInt(book[2][0]);
  const halfBookId = book[2][0];
  //console.log("bookdetails", imageNum, halfBookId);
  const imageNumArray = imageNum.split(",");
  const paragraphs = book[4];
  const legends = book[5];

  if (!Array.isArray(imageNumArray)) {
    // Handle the case where imageNumArray is not an array
    console.error("imageNumArray is not an array:", imageNumArray);
    // For example, you might want to assign a default value or throw an error
  }

  function getFullBookId(imageNumArray, halfBookId) {
    const fullBookId = imageNumArray.map((num) => `${halfBookId}_${num}`);
    return fullBookId;
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const bookId = getFullBookId(imageNumArray, halfBookId);
       // console.log("bookId", bookId)
        const fetchedImages = [];
        for (let i = 1; i <= 10; i++) {
         // console.log("bookidnum", bookId[i-1 ]);
          //if (fetchedImagesCount >= 10) break;
          const idToken = await user.getIdToken();
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/user/imageOutput`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: idToken,
              },
              body: JSON.stringify({
                bookId: `${bookId[i - 1]}`,
              }),
            }
          );

          if (response.ok) {
           // console.log("respImageDis", response);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            let url;
            await new Promise((resolve) => {
              reader.addEventListener(
                "load",
                () => {
                  url = reader.result;
                  resolve();
                },
                false
              );
            });

            //console.log("url", url);
            // Push paragraphs
            fetchedImages.push({ type: "story", content: paragraphs[i - 1] }); // Assuming legends array matches images count

            // Push image
            fetchedImages.push({ type: "image", url });

            // Push legend
            fetchedImages.push({ type: "legend", content: legends[i - 1] }); // Assuming legends array matches images count
          } else if (response.status === 404) {
            // Log the 404 error and continue polling
            console.warn("Endpoint not ready yet. Retrying in 30 second...");
            await new Promise((resolve) => setTimeout(resolve, 30000));
            i--; // Retry fetching the same image
          } else {
            console.error(`Failed to fetch image: ${i}`, response.status);
          }
        }

        setImages(fetchedImages);
      } catch (error) {
        console.error("Error fetching images:", error);
        await new Promise((resolve) => setTimeout(resolve, 30000));
        fetchData(); // Retry fetching images
      }
    }

    fetchData();

    // Clean up
    return () => {
      images.forEach((item) => {
        if (item.type === "image") {
          URL.revokeObjectURL(item.url);
        }
      });
    };
  }, [user]); //book

  // Combine data as suggested
  const combinedData = [];
  for (let i = 0; i < images.length; i += 3) {
    combinedData.push({
      story: images[i].content,
      image: images[i + 1].url,
      legend: images[i + 2].content,
    });
  }
  //console.log("combinedData2", combinedData);
  return (
    <div>
      <PageNav />
      <div className="flex justify-between">
        <div className="mx-auto bg-white px-6 py-10 lg:px-8">
          <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
            <div className="mx-auto text-base font-semibold leading-7 text-gray-900"></div>
            {storyTitle && (
              <p className="mt-16 text-2xl font-bold tracking-tight text-gray-900 text-center">
                {storyTitle}
              </p>
            )}
            {combinedData.map((item, index) => (
              <div className="mt-10 max-w-2xl" key={index}>
                {item.story && (
                  <p className="font-medium text-gray-700 text-justify">
                    {item.story}
                  </p>
                )}
                <div className="text-center">
                  {item.image && (
                    <img
                      className="mx-auto mt-4 mb-4"
                      src={item.image}
                      alt={`Generated Image ${index}`}
                      width="400"
                      height="400"
                    />
                  )}
                  {item.legend && (
                    <>
                      <p className="text-sm font-semibold text-gray-900 justify-center mt-4 mb-4">
                        {item.legend}
                      </p>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
