import { UseUserAuth } from './context/UseAuthContext';
import PageNav from "./components/PageNav";
import {  CheckBadgeIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react';
import app from "./context/Firebase";
import { getAnalytics, logEvent } from "firebase/analytics";

export default function Success(){
    
    return(
        <>
        <PageNav/>
        <Heading/>
        <div className=" flex justify-center bg-white px-6 py-10 lg:px-8">
      <div role="list" className="mt-8 max-w-2xl space-y-8 text-gray-600 text-justify">
        <div className="flex gap-x-3">
            <CheckBadgeIcon className="mt-1 h-5 w-10 flex-none text-indigo-600" aria-hidden="true" />
            <span>
              <strong className="font-semibold text-gray-900">Purchase Confirmed: </strong> 
              Your transaction is complete, and your credits have been added. Enjoy creating personalized books for your kids!
            </span>
          </div>
          </div>
          </div>
        
        </>
    )
}

function Heading() {
  const { user } = UseUserAuth();
  const [userName, setUserName] = useState("");
  const [userPaymentInfo, setUserPaymentInfo] =useState({});
 // console.log("create user", user);

 const fetchUserName = async () => {
  try {
    const idToken = await user.getIdToken();
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/user/getUserName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
     // body: JSON.stringify({ userId: user.uid }), // Assuming `user.uid` is the user ID
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user name");
    }

    const data = await response.json();
    setUserName(data);
  } catch (error) {
    console.error("Error fetching user name:", error);
  }
};
 const fetchLatestPaymentInfo = async() => {
  try {
    const idToken = await user.getIdToken();
    const response2 = await fetch(`${process.env.REACT_APP_BACKEND}/user/getLatestPaymentInfo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
     // body: JSON.stringify({ userId: user.uid }), // Assuming `user.uid` is the user ID
    });
    if (!response2.ok) {
      throw new Error("Failed to fetch user payment");
    }

    const data = await response2.json();
    const price = data["price"];
    const quantity = data["quantity"];
    const product = data["itemName"];
    const transaction_id = data["transactionId"];

    const revenue = parseInt( price) * parseInt(quantity)
    const result = {price : price , quantity : quantity, item_name : product, value : revenue, transaction_id : transaction_id }
    setUserPaymentInfo(result);
  } catch (error) {
    console.error("Error fetching user payment:", error);
  }
  }
 
useEffect(() => {
  if (user) {
    fetchUserName();
    //console.log("userName", userName);
    fetchLatestPaymentInfo();
  }
}, [user]);

useEffect(() => {
  if (user && Object.keys(userPaymentInfo).length > 0) {
    const price = userPaymentInfo["price"];
    const quantity = userPaymentInfo["quantity"];
    const item_name = userPaymentInfo["item_name"];
    const value = userPaymentInfo["value"];
    const transaction_id = userPaymentInfo["transaction_id"];
    let item_id ; 
    if (item_name==="Pay-as-you-go"){
        item_id = 1
    }else{
      item_id = 2
    }
    const currency = "USD"
    const items = [{item_id, item_name, price, quantity}]
    const userInfo = {currency , value, transaction_id, items}
    console.log("userName", userName)
    const analytics = getAnalytics(app);
    logEvent(analytics, "purchase", userInfo);
    console.log("userPaymentInfo",userInfo)
  }
}, [userPaymentInfo]);

return (
  <div className="flex justify-center text-lg font-semibold p-4 box mt-3 text-center text-indigo-600">
    Welcome <br />
    {userName || (user ? (user.displayName ? user.displayName : user.email) : "Guest")}
  </div>
);
}
