import PageNav from "./components/PageNav";
import { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import {
  acceptUser,
  redirectSignIn,
  UseUserAuth,
} from "./context/UseAuthContext";
//import LoginAlert from "./LoginAlertBox";
import WrLoginAlert from "./WrongLoginAlertBox";
import PasswordAlert from "./PasswordAlertBox";
import app from "./context/Firebase";
import { getAnalytics, logEvent } from "firebase/analytics";
//import GoogleButton from "react-google-button";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { logIn } = UseUserAuth();
  const { passwordResetEmail } = UseUserAuth();
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility
  //const [alertBox, setAlertBox] = useState(false);
  const [wrongLoginAlert, setWrongLoginAlert] = useState(false);
  const [passwordResetAlert, setPasswordResetAlert] = useState(false);
  const { googleSignIn } = UseUserAuth();
  const navigate = useNavigate();
  //const {user} = UseUserAuth();

  async function handleSubmit(e) {
    e.preventDefault();
    setWrongLoginAlert(false); // Reset the alert state before attempting login
    try {
      const userCredential = await logIn(email, password);
      const provider = "Email";
      const name = "";
      console.log("login", provider, name)
      await acceptUser(userCredential, navigate, provider, name);
      const analytics = getAnalytics(app);
      logEvent(analytics, "login", {method:provider});
      console.log("login",provider)
    } catch (error) {
      console.log("error", error);
      setWrongLoginAlert(true);
    }
  }

  async function handleGoogleSignIn(e) {
    e.preventDefault();
    setWrongLoginAlert(false); // Reset the alert state before attempting login
    try {
      await googleSignIn();
      const analytics = getAnalytics(app);
      logEvent(analytics, "login", {method:"Google"});
      console.log("login","Google")
    } catch (error) {
      console.log("Sign in error with google");
    }
  }

  async function resetPassword(e) {
    e.preventDefault();
    try {
      await passwordResetEmail(email);
      setPasswordResetAlert(true);
      //alert("Password reset email sent")
    } catch (error) {
      setError(error.message);
    }
  }

  useEffect(() => {
    redirectSignIn(navigate);
  }, [navigate]);

  return (
    <>
      <PageNav />
      <div className="flex min-h-full flex-1 flex-col justify-center py-8 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-14 w-auto"
            src="https://static.mytalemaker.com/logo-icon.png"
            alt="MyTaleMaker"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Log in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    style={{ padding: "10px" }}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2 relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"} // Use state to toggle type                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    style={{ padding: "10px" }}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                    onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                  >
                    {showPassword ? "Hide" : "Show"}
                  </button>
                </div>
              </div>
              {/*}                   className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
               */}
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="flex w-full justify-center items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  style={{ width: "189px", height: "40px" }}
                >
                  Sign in
                </button>
              </div>
            </form>

            <div className="flex flex-col items-center justify-center mt-2">
              {/*} <div className="text-sm leading-6">  </div> */}
              <button
                className="text-sm font-semibold text-indigo-600 hover:text-indigo-500 mt-2"
                onClick={resetPassword}
              >
                Forgot password?
              </button>

              <div className="text-center mt-4">
                <p className="text-gray-700">
                  Don't have an account?{" "}
                  <NavLink
                    to="/signup"
                    className="text-sm text-indigo-500 hover:text-indigo-700 font-semibold"
                  >
                    Sign up
                  </NavLink>
                </p>
              </div>
            </div>
            {/* Separator with "OR" */}

            <div className="relative mt-6 flex items-center">
              <div className="flex-grow border-t border-gray-300"></div>
              <span className="px-4 text-gray-500 whitespace-nowrap">OR</span>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>

            {/*className="flex items-center justify-center mt-6 w-full bg-white border border-gray-300 rounded-md px-4 py-2 shadow-sm hover:bg-gray-50" */}

            <button
              className="mt-6 w-full"
              onClick={handleGoogleSignIn}
              style={{ padding: 0, border: "none", background: "none" }}
            >
              <img
                src="https://static.mytalemaker.com/web_light_sq_SI.svg" // replace with the correct path to your SVG file
                alt="Google icon"
                className="w-full"
                style={{ height: "40px", objectFit: "contain" }}
              />
            </button>
          </div>
        </div>
      </div>
      {wrongLoginAlert && (
        <WrLoginAlert
          onConfirm={() => setWrongLoginAlert(false)} // Just close the dialog on confirm
        />
      )}
      {passwordResetAlert && (
        <PasswordAlert
          onConfirm={() => setPasswordResetAlert(false)} // Just close the dialog on confirm
        />
      )}
    </>
  );
}

export default Login;
