/* eslint-disable react/prop-types */
import React from 'react';
import { Navigate } from "react-router-dom";
import { UseUserAuth } from './context/UseAuthContext';

function ProtectedRoute ({ children }) {
  const { user } = UseUserAuth();

  //console.log("Check user in Private: ", user);
  if (!user) {
    return <Navigate to="/" />;
  }
  return children;
}

export default ProtectedRoute;