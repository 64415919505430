import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";

import About from "./About";
import Homepage from "./Homepage";
import Contact from "./Contact";
import Login from "./Login";
import Signup from "./Signup";
import Gallery from "./Gallery";
import ProtectedRoute from "./ProtectedRoute";
import Cart from "./Cart";
import Library from "./Library";
import Account from "./Account";
import Create from "./Create";
import BookDetails from "./BookDetails";
import Success from "./Success";
import Cancel from "./Cancel";
//import Pricing from './Pricing';
import PageNotFound from "./PageNotFound";
import EditBookDetails from "./EditBook";
import PrivacyPolicy from "./PrivacyPolicy";
import Blog from "./Blog";

import "./index.css";
import app from "./context/Firebase";
import { UserAuthContextProvider } from "./context/UseAuthContext";
import SampleBookDetails from "./SampleBookDetails";
import { getAnalytics, logEvent } from "firebase/analytics";

import ScrollToTop from './ScrollToTop';
import AboutUs from "./AboutUs";
import BlogPost from "./BlogPost";

export default function App() {
  useEffect(() => {
    console.log("log something");
    const analytics = getAnalytics(app);
    logEvent(analytics, "notification_received");
  }, []);

  return (
    <div>
      <UserAuthContextProvider>
        <BrowserRouter>
        <ScrollToTop />
          <Routes>
            <Route path="/" element={<Homepage />} />
            
            <Route path="about" element={<About />} />
            <Route
              path="account"
              element={
                <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
              }
            />
            <Route
              path="create"
              element={
                <ProtectedRoute>
                  <Create />
                </ProtectedRoute>
              }
            />
            <Route
              path="library"
              element={
                <ProtectedRoute>
                  {" "}
                  <Library />
                </ProtectedRoute>
              }
            />
            <Route
              path="/library/:slug"
              element={
                <ProtectedRoute>
                  <BookDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/library/:slug/edit"
              element={
                <ProtectedRoute>
                  <EditBookDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="cart"
              element={
                <ProtectedRoute>
                  {" "}
                  <Cart />
                </ProtectedRoute>
              }
            />
            <Route
              path="success"
              element={
                <ProtectedRoute>
                  {" "}
                  <Success />
                </ProtectedRoute>
              }
            />
            <Route
              path="cancel"
              element={
                <ProtectedRoute>
                  {" "}
                  <Cancel />
                </ProtectedRoute>
              }
            />
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="gallery" element={<Gallery />} />   
            <Route path="/gallery/:slug" element={<SampleBookDetails />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPost/>} />
            <Route path='/policy' element={<PrivacyPolicy/>}/>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </UserAuthContextProvider>
    </div>
  );
}
