import { BoltIcon, BookOpenIcon, FaceSmileIcon, HandThumbUpIcon, StarIcon, UserCircleIcon } from '@heroicons/react/20/solid'

const features = [{
  name: "Tailored to Your Child's Imagination",
  description:
  "Crafted by our AI using your family's input, each unique short story come alive with 10 captivating paragraphs and 10 beautiful illustrations. Parents, kids, aunts, uncles, and grandparents can all contribute, making it a treasure your family will cherish forever.",
    
  icon: BoltIcon,
},
{
  name: "Nurture Good Habits and Moral Values",
  description:
  "Empower your child's moral compass with our personalized storybooks! Tailor-made for ages 2-7, you can customize the protagonist's name, gender, and even select a moral value or provide any description. Each story can foster valuable life lessons, depending on your input, making learning fun and deeply personal.",
  icon: StarIcon,
},
{
  name: "Create Unforgettable Memories",
  description:
    "Building these books with your kids and reading them together creates special moments they will eagerly look forward to. Whether you create childhood stories, bedtime stories for kids or any other short stories for kids, these tales will resonate with your child, adding excitement and making your shared reading time memorable. This fosters a deeper connection and creates cherished moments that last a lifetime.",
  icon: FaceSmileIcon,
},

{
  name: "20 Image Revisions for Customer Satisfaction",
  description:"Our storybooks weave captivating narratives across 10 beautifully illustrated paragraphs. And for your complete satisfaction, we offer a generous 20 AI-powered image revisions for each book.  That's right, if any illustration doesn't perfectly capture your vision, our AI will create up to 20 alternatives until you find the perfect one.",
  icon: HandThumbUpIcon,
},


{
  name: "Foster love for reading",
  description:
  'Our personalised storybooks ignite an interest in reading, enhancing literacy skills while sparking curiosity and creativity.',
  icon: BookOpenIcon,
},
{
  name: " Craft Meaningful Alternatives to Generic Online Content",
  description:
  'Our Personalized storybooks offer a refreshing alternative to generic online videos, providing tailored content that engages and educates children in a meaningful way.',

  href: '#',
  icon: UserCircleIcon,
}
]
function About() {
    return (
      <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Discover the joy of personalized storytelling
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Create personalized storybooks that inspire, delight, and ignite imagination – because every child deserves a tale as unique as they are!
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <feature.icon className="h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                   
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
    );
  }

export default About;