import { UseUserAuth } from './context/UseAuthContext';
import PageNav from "./components/PageNav";
import {  XCircleIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react';


export default function Cancel(){
    return(
        <>
        <PageNav/>
        <Heading/>
        <div className=" flex justify-center bg-white px-6 py-10 lg:px-8">
      <div role="list" className="mt-8 max-w-2xl space-y-8 text-gray-600 text-justify">
        <div className="flex gap-x-3">
            <XCircleIcon className="mt-1 h-5 w-10 flex-none text-indigo-600" aria-hidden="true" />
            <span>
              <strong className="font-semibold text-gray-900">Purchase Denied: </strong> 
              Apologies, but we were unable to process your payment at this time. Please try again later. If you need further assistance, please don't hesitate to contact us.
            </span>
          </div>
          </div>
          </div>
        
        </>
    )
}

function Heading() {
  const { user } = UseUserAuth();
  const [userName, setUserName] = useState("");
 // console.log("create user", user);

 const fetchUserName = async () => {
  try {
    const idToken = await user.getIdToken();
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/user/getUserName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
     // body: JSON.stringify({ userId: user.uid }), // Assuming `user.uid` is the user ID
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user name");
    }

    const data = await response.json();
    setUserName(data);
  } catch (error) {
    console.error("Error fetching user name:", error);
  }
};

useEffect(() => {
  if (user) {
    fetchUserName();
  }
}, [user]);

return (
  <div className="flex justify-center text-lg font-semibold p-4 box mt-3 text-center text-indigo-600">
    Welcome <br />
    {userName || (user ? (user.displayName ? user.displayName : user.email) : "Guest")}
  </div>
);
}
