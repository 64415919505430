import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageNav from "./components/PageNav";
import ReactMarkdown from 'react-markdown';


export default function BlogPost() {
  const { slug } = useParams();
  const [content, setContent] = useState('');

  useEffect(() => {
    import(`./assets/blogposts/${slug}.md`)
      .then(res => fetch(res.default))
      .then(res => res.text())
      .then(setContent)
      .catch(err => console.error(err));
  }, [slug]);

  return (
    <>
      <PageNav className="absolute inset-x-0 top-0 z-50" />
      <div className="flex justify-between text-gray-700">
        <div className="mx-auto bg-white px-6 py-10 lg:px-8 text-gray-700">
          <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700 font-medium"> {/*text-justify */}
            <div >
              <ReactMarkdown
                components={{
                  h1: ({ children }) => <h1 className="mt-10 mb-5 text-2xl font-bold tracking-tight text-gray-900 text-center">{children}</h1>,
                  h2: ({ children }) => <h2 className="mt-5 text-xl font-semibold text-gray-900 mb-5">{children}</h2>,
                  h3: ({ children }) => <h3 className="text-xl font-medium my-2">{children}</h3>,
                  strong: ({ children }) => <strong className="mt-10 font-semibold text-gray-900 ">{children}</strong> , /*text-justify */
                  em: ({ children }) => <em className="italic text-gray-700">{children}</em>,
                  p: ({ children }) => <p className="mt-5 text-gray-700">{children}</p>, /*text-justify */
                  li: ({ children }) => <li className="text-gray-700 list-disc ml-5" >{children}</li>, // Ensure list items also have the same text color
                  // Add more components as needed
                  ul: ({ children }) => (
                    <ul className="list-disc ml-5">
                      {children}
                    </ul>
                  ),
                  ol: ({ children }) => (
                    <ol className="list-decimal ml-5">
                      {children}
                    </ol>
                  ),
                  a: ({ children, href }) => (
                    <a href={href} className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                      {children}
                    </a>
                  ),
                }}
              >{content}</ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


    
    
  