//this is an extension of gallery
import React from 'react';
import { useLocation } from 'react-router-dom';
import PageNav from "./components/PageNav";

export default function SampleBookDetails()  {
    const location = useLocation();
    const {sampleBookData} = location.state; 
    const storyTitle = sampleBookData.storyTitle 
    //console.log("sample title", storyTitle)

    return  (
        <div>
            <PageNav />
            <div className="flex justify-between">
                <div className="mx-auto bg-white px-6 py-10 lg:px-8">
                    <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                        <div className="mx-auto text-base font-semibold leading-7 text-gray-900">
                        </div>
                        {sampleBookData.title && (
                            <p className="mt-16 text-2xl font-bold tracking-tight text-gray-900 text-center">
                                {sampleBookData.title}
                            </p>
                        )}
                        {sampleBookData.paragraphs && (
                            sampleBookData.paragraphs.map((paragraph, index) => (
                                <div className="mt-10 max-w-2xl" key={index}>
                                    <p className="font-medium text-gray-700 text-justify">
                                        {paragraph}
                                    </p>
                                    <div className="text-center">
                                        {sampleBookData.images && (
                                            <img
                                                className="mx-auto mt-4 mb-4"
                                                src={sampleBookData.images[index]}
                                                alt={`Generated Image ${index}`}
                                                width="400"
                                                height="400"
                                            />
                                        )}
                                        {sampleBookData.legends && (
                                            <p className="text-sm font-semibold text-gray-900 justify-center mt-4 mb-4">
                                                {sampleBookData.legends[index]}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    )}

