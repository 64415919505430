import { createContext, useContext, useState, useEffect } from "react";
import { sendEmailVerification } from "firebase/auth";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";
import { auth } from "./Firebase";

export async function acceptUser(userCredential, navigate, provider, name) {
  const user = userCredential.user;
  const service_provider = provider;
  if (user) {
    if (!user.emailVerified) {
      await sendEmailVerification(user);
    }
    const idToken = user["accessToken"];
    const displayName = name ? name : user.displayName ? user.displayName : "";
    console.log("AcceptUser - name:", displayName);
    const info = {
      emailAdd: user["email"],
      name: displayName,
      createdAt: user["metadata"]["creationTime"],
      lastLogin: user["metadata"]["lastSignInTime"],
      emailVerified: user["emailVerified"],
      provider: service_provider,
    };
    console.log("AcceptUser - info:", info);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/user/usersInfo`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken,
        },
        body: JSON.stringify(info),
      }
    );
    if (!response.ok) {
      console.log("Info not sent to the personal db");
    } else {
      console.log("Successfully sent info to backend");
    }

    navigate("/account");
  } else {
    console.log("not a user!");
  }
}

export async function redirectSignIn(navigate) {
  if (window.sessionStorage.getItem("AuthPending")) {
    window.sessionStorage.removeItem("AuthPending");
    // show spinner
    const result = await getRedirectResult(auth);
    console.log("sign in result", result);
    // hide spinner
    try {
      const provider = "Google";
      console.log("signup page provider", provider, result.user.displayName);
      acceptUser(result, navigate, provider, result.user.displayName);
    } catch (error) {
      console.log("Sign in error with google", error);
    }
  }
}

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});

  async function signUp(email, password, name) {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
   
    return userCredential;
  }

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logOut() {
    return signOut(auth);
  }

  function passwordResetEmail(email) {
    return sendPasswordResetEmail(auth, email);
  }

  async function googleSignIn() {
    window.sessionStorage.setItem("AuthPending", 1);
    const googleAuthProvider = new GoogleAuthProvider();
    signInWithRedirect(auth, googleAuthProvider);

  }

  useEffect(() => {
    //const user = auth.currentUser;
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      // console.log("Auth", user)
    });
    return () => {
      unsubscribe();
      //console.log("Auth2", user)
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        user,
        signUp,
        logIn,
        logOut,
        passwordResetEmail,
        googleSignIn,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function UseUserAuth() {
  return useContext(userAuthContext);
}
