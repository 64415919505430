import { useState, useEffect } from "react";
import {
  acceptUser,
  redirectSignIn,
  UseUserAuth,
} from "./context/UseAuthContext";
import PageNav from "./components/PageNav";
//import { sendEmailVerification } from "firebase/auth";
//import SignupAlert from "./SignupAlertBox";
import PasswordLenAlert from "./PasswordLenAlertBox";
import { useNavigate, NavLink } from "react-router-dom";
import WrEmailAlert from "./WrongEmailAlertBox";
import app from "./context/Firebase";
import { getAnalytics, logEvent } from "firebase/analytics";

function Signup() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const { signUp } = UseUserAuth();
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility
  // const {logIn} = UseUserAuth();
  const [error, setError] = useState("");
  //const [alertBox, setAlertBox] = useState(false);
  const [alertPasswordBox, setAlertPasswordBox] = useState(false);
  const [wrongEmailAlert, setWrongEmailAlert] = useState(false);
  const navigate = useNavigate();

  const { googleSignIn } = UseUserAuth();
  //const [googleSignInTrigger, setGoogleSignInTrigger] = useState(false); // State to trigger useEffect

  async function handleSubmit(e) {
    e.preventDefault();
    setError("");
    if (password.length < 6) {
      setAlertPasswordBox(true);
      return;
      //alert('The password must contain a minimum of six characters.')
    }

    try {
      //first check the email user provided is valid
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setWrongEmailAlert(true);
        return;
        //alert("Invalid email format");
      }

      const name = firstName + " " + lastName;
      const userCredential = await signUp(email, password, name);
      console.log("Signup - userCredential:", userCredential);
      const provider ="Email"
      await acceptUser(userCredential, navigate,  provider, name );
      const analytics = getAnalytics(app);
      logEvent(analytics, "sign_up", {method:provider});
      console.log("sign_up",provider)
    } catch (error) {
      console.error("Signup error:", error);
      setError(error.message);
    }
  }

  async function handleGoogleSignIn(e) {
    e.preventDefault();
    try {
      //setGoogleSignInTrigger(true)
      await googleSignIn();
      //setGoogleSignInTrigger(true); // Set to true to trigger useEffect
      const analytics = getAnalytics(app);
      logEvent(analytics, "sign_up", {method:"Google"});
      console.log("sign_up","Google")
    } catch (error) {
      console.log("Sign in error with google");
    }
  }

  useEffect(() => {
    redirectSignIn(navigate);
    //setGoogleSignInTrigger(false); // Reset the trigger
  }, [navigate]);

  return (
    <>
      <PageNav />
      <div className="flex min-h-full flex-1 flex-col justify-center py-8 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-14 w-auto"
            src="https://static.mytalemaker.com/logo-icon.png"
            alt="MyTaleMaker"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign up
          </h2>
        </div>

        <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2">
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    autoComplete="given-name"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    style={{ padding: "10px" }}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Last name
                </label>
                <div className="mt-2">
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    autoComplete="family-name"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    style={{ padding: "10px" }}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    style={{ padding: "10px" }}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2 relative">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"} // Use state to toggle type                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    style={{ padding: "10px" }}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                    onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                  >
                    {showPassword ? "Hide" : "Show"}
                  </button>
                </div>
              </div>

              <p className="text-center text-xs text-gray-500 mt-2 mb-4">
                By registering for and using our service, you consent to our
                <NavLink
                  to="/policy"
                  className="text-indigo-600 hover:text-indigo-800"
                >
                  {" "}
                  Terms of Use And Privacy Policy
                </NavLink>
              </p>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  style={{ width: "189px", height: "40px" }}
                >
                  Sign up
                </button>
              </div>
            </form>
            <div className="text-center mt-4">
              <p className="text-gray-700">
                Already have an account?{" "}
                <NavLink
                  to="/login"
                  className="text-sm text-indigo-500 hover:text-indigo-700 font-semibold"
                >
                  Log In
                </NavLink>
              </p>
            </div>
            <div className="relative mt-6 flex items-center">
              <div className="flex-grow border-t border-gray-300"></div>
              <span className="px-4 text-gray-500 whitespace-nowrap">OR</span>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>

            {/*className="flex items-center justify-center mt-6 w-full bg-white border border-gray-300 rounded-md px-4 py-2 shadow-sm hover:bg-gray-50" */}

            <button
              className="mt-6 w-full"
              onClick={handleGoogleSignIn}
              style={{ padding: 0, border: "none", background: "none" }}
            >
              <img
                src="https://static.mytalemaker.com/web_light_sq_ctn.svg" // replace with the correct path to your SVG file
                alt="Google icon"
                className="w-full"
                style={{ height: "40px", objectFit: "contain" }}
              />
              {/*} <span className="text-gray-600 text-sm font-semibold">
                  Sign in with Google
                </span> */}
            </button>
          </div>
        </div>
      </div>
      {/*}  {alertBox && (
        <SignupAlert
          onConfirm={() => setAlertBox(false)} // Just close the dialog on confirm
        />
      )} */}
      {alertPasswordBox && (
        <PasswordLenAlert
          onConfirm={() => setAlertPasswordBox(false)} // Just close the dialog on confirm
        />
      )}
      {wrongEmailAlert && (
        <WrEmailAlert
          onConfirm={() => setWrongEmailAlert(false)} // Just close the dialog on confirm
        />
      )}
    </>
  );
}

export default Signup;
