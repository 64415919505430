import { Cog6ToothIcon } from "@heroicons/react/20/solid";

export default function CreationInstructions() {
  return (
    <div className=" flex justify-center bg-white px-6 py-16 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center ">
          The Storybook Creation Process
        </h1>{" "}
        <div className="mt-10 max-w-2xl">
          <ul className="list-disc mt-8 max-w-2xl space-y-8 text-gray-600">
            <li className="flex gap-x-3">
              <Cog6ToothIcon
                className="mt-1 h-5 w-10 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>Buy credits.</span>
            </li>
            <li className="flex gap-x-3">
              <Cog6ToothIcon
                className="mt-1 h-5 w-10 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                Provide information about your protagonist, instill moral values
                or add a brief description for enriched storytelling .
              </span>
            </li>
            <li className="flex gap-x-3">
              <Cog6ToothIcon
                className="mt-1 h-5 w-10 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                Let AI weave its magic and craft a unique tale for you in 2
                minutes.
              </span>
            </li>
            <li className="flex gap-x-3">
              <Cog6ToothIcon
                className="mt-1 h-5 w-10 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                If any image fails to meet expectations, just request our AI to
                redraw it, with up to 20 revisions available for each storybook.
              </span>
            </li>
            <li className="flex gap-x-3">
              <Cog6ToothIcon
                className="mt-1 h-5 w-10 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                Read your storybook online or download it as a PDF. The storybook is yours to keep forever.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
