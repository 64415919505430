import { NavLink } from "react-router-dom";
import { UseUserAuth } from '../context/UseAuthContext';
import { useNavigate } from "react-router";


//import { UseUserAuth } from '../src/context/UseAuthContext';
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
//import Logo from "./Logo";

function PageNav() {
  const { user } =  UseUserAuth();
  //console.log("user pagenav", user)
  const {logOut} = UseUserAuth();
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  async function handleLogout(){
    try {
      await logOut();
      navigate("/");
      localStorage.removeItem('emailVerifiedStatus');
      console.log('Navigating to home page...');
    } catch(error) {
      console.log(error.message);
    }
  } 
  return (
    <div className="sticky top-0 z-50 bg-slate-50">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <NavLink to="/" className="-m-1.5 p-1.5">
            {" "}
            {/* here I have to put homepage 8*/}
            <span className="sr-only">MyTaleMaker</span>
            {/*   <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" /> 
         <img className="h-16 w-auto" src="https://static.mytalemaker.com/Print_Transparent.svg" alt="" />*/}
            <img
              className="h-8 w-auto"
              //src="https://static.mytalemaker.com/fullName.png"
              //src="https://static.mytalemaker.com/logo-full.png"
              //src="https://static.mytalemaker.com/logo-icon-fitting.png" //h-7 or h-8 looks ok but then the mobile sign pushes log out in 2 lines
              src="https://static.mytalemaker.com/logo-icon-fitting2.png"
              
              alt="mytalemaker-logo"
            />
          </NavLink>
        </div>
        <div className=" hidden lg:flex lg:gap-x-12">
          <NavLink
            to="/"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Home
          </NavLink>
        
          <NavLink
            to="/gallery"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Gallery
          </NavLink>
          <NavLink
            to="/about-us"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            About Us
          </NavLink>
          <NavLink
            to="/blog"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Blog
          </NavLink>
          
        
          {user && (
            <>
              <NavLink
                to="/account"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Account
              </NavLink>
              <NavLink
                to="/create"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Create
              </NavLink>
              <NavLink
                to="/library"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Library
              </NavLink>
              <NavLink
                to="/cart"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cart
              </NavLink>
            </>
          )}
        </div>
        {!user && (
          <div className="flex flex-1 items-center justify-end gap-x-6">
            <NavLink
              to="/login"
              className="hidden lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-gray-900"
            >
              Log in
            </NavLink>
            <NavLink
              to="/signup"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign up
            </NavLink>
          </div>
        )}

        {user && (
          <div className="flex flex-1 items-center justify-end gap-x-6">
            <button
              onClick={handleLogout}
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Log out
            </button>
          </div>
        )}

        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center gap-x-6">
            <NavLink to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">MyTaleMaker</span>
              <img
                className="h-10 w-auto"
                src="https://static.mytalemaker.com/logo-icon.png"
                alt="mytalemaker-logo2"
              />
            </NavLink>
            {!user && (
              <NavLink
                to="/signup"
                className="ml-auto rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign up
              </NavLink>
            )}
            {user && (
              <div className="flex flex-1 items-center justify-end gap-x-6">
                <button
                  onClick={handleLogout}
                  type="submit"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Log out
                </button>
              </div>
            )}
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                
                <NavLink
                  to="/gallery"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Gallery
                </NavLink>
                <NavLink
                  to="/about-us"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  About Us
                </NavLink>
                <NavLink
                  to="/blog"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Blog
                </NavLink>
                
                {user && (
                  <>
                    <NavLink
                      to="/account"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Account
                    </NavLink>
                    <NavLink
                      to="/create"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Create
                    </NavLink>
                    <NavLink
                      to="/library"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Library
                    </NavLink>
                    <NavLink
                      to="/cart"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Cart
                    </NavLink>
                  </>
                )}
              </div>
              {!user && (
                <div className="py-6">
                  <NavLink
                    to="/login"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
}

export default PageNav;
